@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* For use outside of tailwind */
    --color-color-secondary-50: #edf6f8;
    --color-color-secondary-100: #c6e2e9;
    --color-color-secondary-200: #abd4df;
    --color-color-secondary-300: #84c1d0;
    --color-color-secondary-400: #6db5c7;
    --color-color-secondary-500: #48a2b9;
    --color-color-secondary-600: #4293a8;
    --color-color-secondary-700: #337383;
    --color-color-secondary-800: #285966;
    --color-color-secondary-900: #1e444e;

    --color-color-primary-50: #ebebeb;
    --color-color-primary-100: #c0c0c0;
    --color-color-primary-200: #a1a1a1;
    --color-color-primary-300: #767676;
    --color-color-primary-400: #5c5c5c;
    --color-color-primary-500: #333333;
    --color-color-primary-600: #2e2e2e;
    --color-color-primary-700: #242424;
    --color-color-primary-800: #1c1c1c;
    --color-color-primary-900: #151515;
    /* For use outside of tailwind */

    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 217.2 32.6% 17.5%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body,
  html {
    @apply bg-background text-foreground overflow-x-hidden;
  }
}

.no-scroll {
  overflow: hidden;
}
